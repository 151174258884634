/**
 * Parse an inputted string as a JSON Pointer. This function will handle
 * un-escaping the special sequences "~0" and "~1", as per [RFC 6901, Section
 * 3](https://tools.ietf.org/html/rfc6901#section-3).
 *
 * @param s The string to parse as a JSON Pointer.
 * @throws [[Error]] If the input does not correspond to a valid
 * JSON Pointer.
 */
export function parseJsonPointer(s: string): string[] {
  // From the ABNF syntax of JSON Pointer, the only valid initial character
  // for a JSON Pointer is "/". Empty strings are acceptable.
  //
  // Other than this limitation, all strings are valid JSON Pointers.
  if (s === '') {
    return [];
  }

  if (!s.startsWith('/')) {
    throw new Error('Invalid JSON Pointer syntax: missing leading "/"');
  }

  const [, ...tokens] = s.split('/');
  return tokens.map((token) => {
    return token.replace(/~1/g, '/').replace(/~0/g, '~');
  });
}
