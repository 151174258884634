export function flattenArrayByKeyRecursive<T>(array: T[], key: keyof T): T[] {
  return array.reduce((acc, item) => {
    if (Array.isArray(item[key])) {
      return [
        ...acc,
        item,
        ...flattenArrayByKeyRecursive(item[key] as T[], key),
      ];
    }

    return acc;
  }, [] as T[]);
}
