import { set } from 'lodash';

export function fieldPathToObject<T extends object, K extends keyof T>(
  obj: T,
  fieldPathKey: K,
): T {
  const result: T = {} as any;
  const fieldPath: string = obj[fieldPathKey] as any;

  for (const [key, value] of Object.entries(obj)) {
    set(result, fieldPath, value);
  }

  return result;
}
