import {
  AbilityClass,
  MongoAbility,
  PureAbility,
  createMongoAbility,
} from '@casl/ability';

import {
  ActivityCommentFragment,
  ActivityFragment,
  EmailFragment,
  EmployeeContractFragment,
  EmployeeFragment,
  MediaFragment,
  NoteFragment,
  ProjectFragment,
  TimeEntryFragment,
} from '@husky/app/shared/data-access';
import { AbilityAction, AbilitySubjectTuple } from '@husky/shared-util-ability';

export type AppAbilitySubjectUnion =
  | ProjectFragment
  | MediaFragment
  | NoteFragment
  | EmailFragment
  | ActivityFragment
  | ActivityCommentFragment
  | TimeEntryFragment
  | EmployeeFragment
  | EmployeeContractFragment;

export type AppAbilitySubjectTuple =
  | [AbilityAction, AppAbilitySubjectUnion]
  | AbilitySubjectTuple;

export type AppAbility = MongoAbility<AppAbilitySubjectTuple>;
export const AppAbility = PureAbility as AbilityClass<AppAbility>;

export function createAppAbility(): AppAbility {
  return createMongoAbility([], {
    detectSubjectType: (object) => object['__typename'],
  }) as any;
}
