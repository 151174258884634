export function isPlainObject(obj: any): obj is Record<string | number, any> {
  return (
    obj !== null &&
    typeof obj === 'object' &&
    (Object.getPrototypeOf(obj) === Object.prototype ||
      Object.getPrototypeOf(obj) === null)
  );
}

// Matches any primitive value: https://developer.mozilla.org/en-US/docs/Glossary/Primitive.
type Primitive = null | undefined | string | number | boolean | symbol | bigint;

// DeepOmit primitives include functions since these are unmodified.
type DeepOmitPrimitive = Primitive | Function;

export type DeepOmitArray<T extends any[], K> = {
  [P in keyof T]: DeepOmit<T[P], K>;
};

// Unfortunately there is one major flaw in this type: This will omit properties
// from class instances in the return type even though our omitDeep helper
// ignores class instances, therefore resulting in a type mismatch between
// the return value and the runtime value.
//
// It is not currently possible with TypeScript to distinguish between plain
// objects and class instances.
// https://github.com/microsoft/TypeScript/issues/29063
//
// This should be fine as of the time of this writing until omitDeep gets
// broader use since this utility is only used to strip __typename from
// `variables`; a case in which class instances are invalid anyways.
export type DeepOmit<T, K> = T extends DeepOmitPrimitive
  ? T
  : {
      [P in Exclude<keyof T, K>]: T[P] extends infer TP
        ? TP extends DeepOmitPrimitive
          ? TP
          : TP extends any[]
            ? DeepOmitArray<TP, K>
            : DeepOmit<TP, K>
        : never;
    };

export function omitDeep<T, K extends string>(value: T, key: K) {
  return __omitDeep(value, key);
}

function __omitDeep<T, K extends string>(
  value: T,
  key: K,
  known = new Map<any, any>(),
): DeepOmit<T, K> {
  if (known.has(value)) {
    return known.get(value);
  }

  let modified = false;

  if (Array.isArray(value)) {
    const array: any[] = [];
    known.set(value, array);

    value.forEach((value, index) => {
      const result = __omitDeep(value, key, known);
      modified ||= result !== value;

      array[index] = result;
    });

    if (modified) {
      return array as DeepOmit<T, K>;
    }
  } else if (isPlainObject(value)) {
    const obj = Object.create(Object.getPrototypeOf(value));
    known.set(value, obj);

    Object.keys(value).forEach((k) => {
      if (k === key) {
        modified = true;
        return;
      }

      const result = __omitDeep(value[k], key, known);
      modified ||= result !== value[k];

      obj[k] = result;
    });

    if (modified) {
      return obj;
    }
  }

  return value as DeepOmit<T, K>;
}
