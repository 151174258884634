import { NgModule } from '@angular/core';

import { AppAbility, createAppAbility } from './ability';

@NgModule({
  imports: [],
  providers: [
    {
      provide: AppAbility,
      useFactory: createAppAbility,
    },
  ],
})
export class AppSharedUtilAbilityModule {}
